import { Dialog, DialogClose, DialogContent } from '@shared/design-system/atoms/dialog';
import Image from 'next/image';

import ProductDetailsNutrition from '~/assets/images/shared/nutrition-label.png';

type Props = {
  isOpened: boolean;
  setIsOpened: (open: boolean) => void;
};

const NutritionLabelDialog = ({ isOpened, setIsOpened }: Props) => {
  return (
    <Dialog open={isOpened} onOpenChange={setIsOpened}>
      <DialogContent>
        <DialogClose />
        <Image src={ProductDetailsNutrition} width={592} height={560} alt='Nutrition' />
      </DialogContent>
    </Dialog>
  );
};

export default NutritionLabelDialog;
