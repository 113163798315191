import Image, { StaticImageData } from 'next/image';

import CheckmarkCircleSvg from '~/assets/icons/checkmark-circle.svg';
import StarSvg from '~/assets/icons/star.svg';

type TestimonialProps = {
  title: string;
  description: string;
  name: string;
  rating: number;
  image: StaticImageData;
};

const Testimonial = ({ title, description, name, rating, image }: TestimonialProps) => {
  return (
    <div className='flex flex-col flex-1 bg-white p-4 border border-neutral-0/5 rounded-2xl'>
      <div className='flex items-start gap-4'>
        <div className='flex flex-col flex-1 gap-2'>
          <div className='flex items-center gap-0.5'>
            {[...Array(rating)].map((_, index) => (
              <StarSvg key={index} />
            ))}
          </div>
          <h5 className='font-medium'>{title}</h5>
          <p className='font-xs'>{description}</p>
          <p className='font-medium text-sm'>{name}</p>
          <div className='inline-flex items-center self-start gap-1 bg-green-100 px-1.5 py-1 rounded-3xl'>
            <CheckmarkCircleSvg />
            <p className='text-green-600 text-xs'>Verified Buyer</p>
          </div>
        </div>
        <div className='max-w-[6.25rem] aspect-[100/155] overflow-hidden'>
          <Image src={image} width={100} height={155} alt='review' className='rounded-sm w-full h-full object-cover' />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
