import { AppRoute } from '@shared/common';
import Link from 'next/link';
import React from 'react';

import { useAppRouter } from '~/hooks/useAppRouter';

type ReviewType = {
  videoUrl: string;
  content: string;
};

const Review = ({ videoUrl, content }: ReviewType) => {
  const { getNextFunnelRounte, isPathPlans } = useAppRouter(AppRoute.QUIZ);
  const route = getNextFunnelRounte();

  return (
    <div className='relative rounded-2xl aspect-[258/405] overflow-hidden'>
      <video className='w-full h-full object-cover' autoPlay loop muted preload='metadata'>
        <source src={videoUrl} type='video/mp4' />
      </video>
      <div className='group-[&.swiper-slide-active]:bg-transparent absolute inset-0 flex flex-col md:bg-gradient-to-b md:from-black/0 md:to-black/50 px-6 py-4'>
        <div className='flex flex-col flex-1 justify-end gap-2 opacity-0 md:opacity-100 group-[&.swiper-slide-active]:opacity-100 transition-opacity'>
          <p className='text-white text-sm'>{content}</p>
          {isPathPlans ? null : (
            <Link href={route} className='flex-grow-0 font-medium text-white text-base'>
              Take the quiz
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Review;
