import { Content } from '~/components/content';

import { Table } from './table';

type ComparisonProps = {
  title: string;
  subTitle: string;
  tableItems: string[];
};

const Comparison = ({ title, subTitle, tableItems }: ComparisonProps) => {
  return (
    <Content gridClassName='col-[edge-left_/_edge-right] py-12 bg-white'>
      <div className='flex flex-col gap-6 md:gap-8'>
        <div className='flex flex-col items-center gap-1 md:py-10'>
          <h2 className='font-tertiary font-bold text-3xl md:text-5xl text-center leading-none whitespace-pre-line md:whitespace-normal'>
            {title}
          </h2>
          <p className='font-tertiary text-2xl text-center leading-none whitespace-pre-line md:whitespace-normal'>
            {subTitle}
          </p>
        </div>
        <Table items={tableItems} />
      </div>
    </Content>
  );
};

export default Comparison;
