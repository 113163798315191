import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import { Content } from '~/components/content';

import Review from './review';

type ReviewsProps = {
  reviews: {
    id: number;
    videoUrl: string;
    content: string;
  }[];
};

const Reviews = ({ reviews }: ReviewsProps) => {
  return (
    <Content
      gridClassName='col-[edge-left_/_edge-right] bg-orange-50 py-12'
      viewClassName='col-[edge-left_/_edge-right]'
    >
      <div>
        <Swiper
          className='md:!px-4'
          spaceBetween={24}
          slidesPerView={1.5}
          centeredSlides
          loop
          breakpoints={{
            1024: {
              slidesPerView: 5,
              centeredSlides: false,
              grabCursor: false,
              loop: false,
            },
            768: {
              slidesPerView: 2.5,
              centeredSlides: true,
              loop: true,
            },
          }}
          modules={[Pagination]}
          pagination={{
            el: '#swiperCustomPagination',
            type: 'bullets',
          }}
        >
          {reviews.map(({ id, ...rest }) => (
            <SwiperSlide key={id} className='group'>
              <Review {...rest} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className='lg:hidden flex flex-col items-center gap-2 [&_.swiper-pagination-bullet.swiper-pagination-bullet-active]:bg-black [&_.swiper-pagination-bullet]:bg-black/10 mt-6 px-4 [&_.swiper-pagination-bullet]:rounded-full [&_.swiper-pagination-bullet]:w-2 [&_.swiper-pagination-bullet]:h-2'>
          <div id='swiperCustomPagination' className='flex items-center gap-2' />
          <p className='text-xs'>Swipe left for more!</p>
        </div>
      </div>
    </Content>
  );
};

export default Reviews;
