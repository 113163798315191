import { Content } from '@shared/design-system/atoms/content';
import { cn } from '@shared/design-system/utils/shadcn';
import Image from 'next/image';

import HarmoniaOrangeBadgeImage from '~/assets/images/shared/harmonia-orange-logo-badge.png';
import HarmoniaOrangeLogo from '~/assets/images/shared/harmonia-orange-logo.png';

type Props = {
  bottomBorder?: boolean;
  isBadgeVisible?: boolean;
  button?: JSX.Element;
};

const Header = ({ bottomBorder, isBadgeVisible, button }: Props) => {
  return (
    <Content gridClassName='col-[edge-left_/_edge-right]'>
      <header
        className={cn(
          'flex items-center justify-center py-4 px-4 relative',
          bottomBorder ? 'border-b border-black/5' : '',
          button ? 'gap-4 justify-between' : '',
        )}
      >
        {isBadgeVisible ? (
          <Image
            src={HarmoniaOrangeBadgeImage}
            className={cn('-top-0.5 left-4 absolute', isBadgeVisible && button ? 'hidden md:block' : '')}
            width={33}
            height={55}
            alt='Harmonia logo badge'
            priority
          />
        ) : null}

        <Image
          className={cn(isBadgeVisible ? 'ml-0 md:ml-12' : undefined)}
          src={HarmoniaOrangeLogo}
          width={143}
          height={25}
          alt='Harmonia logo'
        />

        {button ? <div>{button}</div> : null}
      </header>
    </Content>
  );
};

export default Header;
