import { StaticImageData } from 'next/image';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import { Content } from '~/components/content';

import Testimonial from './testimonial';

type TestimonialsProps = {
  title: string;
  subTitle: string;
  testimonials: {
    id: number;
    title: string;
    description: string;
    name: string;
    rating: number;
    image: StaticImageData;
  }[];
};

const Testimonials = ({ title, subTitle, testimonials }: TestimonialsProps) => {
  return (
    <Content
      gridClassName='col-[edge-left_/_edge-right] bg-orange-50 py-12'
      viewClassName='col-[edge-left_/_edge-right]'
    >
      <div className='flex flex-col items-center gap-2 mb-6 md:mb-8 px-4 md:py-12'>
        <h2 className='font-tertiary font-bold text-3xl md:text-5xl text-center leading-none whitespace-pre-line md:whitespace-normal'>
          {title}
        </h2>
        <p className='font-tertiary text-2xl text-center leading-none'>{subTitle}</p>
      </div>

      <Swiper
        className='md:!px-4'
        spaceBetween={16}
        slidesPerView={1.115}
        loop
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
        breakpoints={{
          1280: {
            slidesPerView: 4,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
        }}
        modules={[Pagination]}
        pagination={{
          el: '#swiperCustomTestimonialsPagination',
          type: 'bullets',
        }}
      >
        {testimonials.map(({ id, title, description, name, rating, image }) => (
          <SwiperSlide key={id} className='flex flex-col self-stretch'>
            <Testimonial title={title} description={description} name={name} rating={rating} image={image} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='flex flex-col items-center gap-2 [&_.swiper-pagination-bullet.swiper-pagination-bullet-active]:bg-black [&_.swiper-pagination-bullet]:bg-black/10 mt-6 md:mt-8 px-4 [&_.swiper-pagination-bullet]:rounded-full [&_.swiper-pagination-bullet]:w-2 [&_.swiper-pagination-bullet]:h-2'>
        <div id='swiperCustomTestimonialsPagination' className='flex items-center gap-2' />
        <p className='md:hidden text-xs'>Swipe left for more!</p>
      </div>
    </Content>
  );
};

export default Testimonials;
