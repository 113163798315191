import { Content } from '~/components/content';

const Banner = () => {
  return (
    <Content gridClassName='col-[edge-left_/_edge-right] bg-neutral-0 py-2.5'>
      <p className='font-light text-white text-xs text-center uppercase'>
        <span className='font-bold'>FREE USA SHIPPING</span> FOR ALL ORDERS
      </p>
    </Content>
  );
};

export default Banner;
