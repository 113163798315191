import { cn } from '@shared/design-system/utils/shadcn';
import Image from 'next/image';

import BlackXSvg from '~/assets/icons/black-x.svg';
import GreenCheckmarkSvg from '~/assets/icons/green-checkmark.svg';
import ComparisonHarmoniaImage from '~/assets/images/landing/comparison-harmonia.jpg';
import ComparisonOtherImage from '~/assets/images/landing/comparison-other.jpg';

type TableProps = {
  items: string[];
};

const columnItemClassName = 'w-16 md:w-36';

export const Table = ({ items }: TableProps) => {
  return (
    <div className='flex flex-col'>
      <div className='flex'>
        <div className='flex-1'></div>
        <div className={cn('flex justify-center items-end bg-[#E6F6EC] py-2 rounded-t-lg', columnItemClassName)}>
          <Image src={ComparisonHarmoniaImage} width={124} height={124} alt='Product' quality={100} />
        </div>
        <div className={cn('flex justify-center items-end bg-white py-2 rounded-t-lg', columnItemClassName)}>
          <Image src={ComparisonOtherImage} width={124} height={124} alt='Product' quality={100} />
        </div>
      </div>

      {items.map((item, index) => (
        <div key={index} className='flex'>
          <div className='flex flex-1 items-center py-4 pr-4 border-neutral-0/5 border-b'>
            <p className='text-neutral-0 text-md md:text-lg'>{item}</p>
          </div>
          <div
            className={cn(
              'flex justify-center items-center bg-[#E6F6EC] border-neutral-0/5 border-b',
              columnItemClassName,
            )}
          >
            <GreenCheckmarkSvg />
          </div>
          <div
            className={cn('flex justify-center items-center bg-white border-neutral-0/5 border-b', columnItemClassName)}
          >
            <BlackXSvg />
          </div>
        </div>
      ))}

      <div className='flex min-h-4'>
        <div className='flex-1'></div>
        <div className={cn('bg-[#E6F6EC] rounded-b-lg', columnItemClassName)}></div>
        <div className={cn('bg-white rounded-b-lg', columnItemClassName)}></div>
      </div>
    </div>
  );
};
