import { cn } from '@shared/design-system/utils/shadcn';
import { ReactNode } from 'react';

export const Grid = <C extends React.ElementType = 'main'>({
  as,
  children,
  className,
  ...props
}: { children: ReactNode; className?: string } & {
  as?: C;
} & Omit<React.ComponentPropsWithoutRef<C>, keyof { children: ReactNode; className?: string }>) => {
  const Component = as || 'main';
  return (
    <Component
      className={cn(
        'grid relative',
        'grid-cols-[[edge-left]_16px_[content-start]_min(var(--max-content),calc(100%-32px))_[content-end]_16px_[edge-right]]',
        'md:grid-cols-[[edge-left]_64px_[content-start]_min(var(--max-content),calc(100%-128px))_[content-end]_64px_[edge-right]]',
        'lg:grid-cols-[[edge-left]_minmax(100px,1fr)_[content-start]_min(var(--max-content),calc(100%-200px))_[content-end]_minmax(100px,1fr)_[edge-right]]',
        '2xl:grid-cols-[[edge-left]_1fr_[content-start]_minmax(auto,var(--max-content))_[content-end]_1fr_[edge-right]]',
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  );
};
