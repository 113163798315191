import Image from 'next/image';
import Link from 'next/link';

import PaymentMethodsImage from '~/assets/images/landing/payment-methods.png';
import HarmoniaGreyBadgeImage from '~/assets/images/shared/harmonia-grey-badge.png';
import { Content } from '~/components/content';
import { config } from '~/config';

const navigationUrls = [
  { label: 'Privacy Policy', href: '/privacy-policy' },
  { label: 'Terms & Conditions', href: '/terms-of-services' },
  { label: 'Refund Policy', href: '/refund-policy' },
  { label: 'Contacts', href: '/contacts' },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <Content gridClassName='col-[edge-left_/_edge-right] '>
      <footer className='flex flex-col'>
        <nav className='py-6 border-neutral-0/5 border-b'>
          <ul className='flex flex-wrap justify-center gap-6'>
            {navigationUrls.map(({ label, href }) => (
              <li key={label}>
                <Link className='text-xs' href={href}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <div className='flex flex-col items-center gap-6 py-6'>
          <Image src={HarmoniaGreyBadgeImage} width={40} height={60} alt='Harmonia badge' />
          <div className='flex flex-col gap-4'>
            <div>
              <p className='text-neutral-0/50 text-xs text-center'>
                Contact:{' '}
                <Link className='inline' href={`mailto:${config.EMAIL}`}>
                  {config.EMAIL}
                </Link>
              </p>
              <p className='text-neutral-0/50 text-xs text-center'>
                The statements made on this website have not been evaluated by the FDA (U.S. Food & Drug
                Administration). The products sold on this website are not intended to diagnose, treat, cure, or prevent
                any disease.
              </p>
            </div>
            <div>
              <p className='text-neutral-0/50 text-xs text-center'>
                The information provided by this website or this company is not a substitute for a face-to-face
                consultation with your physician, and should not be construed as individual medical advice.
              </p>
              <p className='text-neutral-0/50 text-xs text-center'>
                Pregnant women: Please consult your doctor before using this product. If you have any concerns please
                consult your doctor or healthcare professional at all times.
              </p>
            </div>
            <div>
              <p className='text-neutral-0/50 text-xs text-center'>
                DrinkHarmonia is in no way affiliated or associated with
              </p>
              <p className='text-neutral-0/50 text-xs text-center'>Facebook*</p>
            </div>
            <p className='text-neutral-0/50 text-xs text-center'>Copyright © {year} Harmonia. All rights reserved.</p>
          </div>
          <Image src={PaymentMethodsImage} width={343} height={31} alt='Payment methods' />
        </div>
      </footer>
    </Content>
  );
};

export default Footer;
